import { Button } from 'antd';
import React from 'react';
import "./CustomButton.scss";

const CustomButton = ({ children, className, variant, icon, style, onClick,loading, size = "large" }) => { // Default size to "middle"
    return (
        <>
            <Button
                onClick={onClick}
                type="primary"
                size={size}
                icon={icon}
                style={style}
                loading={loading}
                // className={!style ? `custom-button ${className} ${variant}` : ""}
                className={`custom-button ${className} ${variant}`}
            >
                {children}
            </Button>
        </>
    );
};

export default CustomButton;
