import React, { useCallback, useEffect, useState } from "react";
import "./CampaignTable.scss";
import { debounce } from "lodash";
import { Table, Tooltip, Dropdown, Spin, Flex } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { postApi } from "../../../Utils/apiService";
import { GET_ALL_CAMPAIGNS } from "../../../Utils/apiPath";
import useTableHeight from "../../../Services/TableHeight";
import ToastHelper from "../../../Services/ToastHelper";

const CampaignTable = ({ onAddClick, onReportClick }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const tableHeight = useTableHeight();

  const debouncedSearch = useCallback(
    debounce((query) => {
      fetchData(pageIndex, pageSize, query);
    }, 500),
    []
  );

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    debouncedSearch(e.target.value);
  };

  const menuItems = (record) => [
    {
      key: "edit",
      label: <a onClick={() => onReportClick(record)}>Update Reports</a>,
    },
  ];

  const columns = [
    {
      title: "SL",
      // dataIndex: "r",
      key: "serialNo",
      width: 30, // fixed width in px
      render: (_, __, index) => (pageIndex - 1) * pageSize + index + 1,
    },
    {
      title: "Campaign ID",
      dataIndex: "campaignID",
      key: "campaignID",
      width: 100, 
    },
    {
      title: "Campaign Name",
      dataIndex: "campaignName",
      key: "campaignName",
      width: 150, 
    },
    {
      title: "Status (Assigned/ Unassigned)",
      dataIndex: "isAssigned",
      key: "status",
      width: 120,
      render: (isAssigned) => (isAssigned ? "Assigned" : "UnAssigned"),
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
      width: 100,
    },
    {
      title: "Assigned Date",
      dataIndex: "assignedDate",
      key: "assignedDate",
      width: 100,
      render: (text) => dayjs(text).format("DD-MM-YYYY"),
    },
    {
      title: "Project No",
      dataIndex: "projectNo",
      key: "projectNo",
      width: 80,
    },
    {
      title: "Action",
      key: "operation",
      width: 30,
      fixed:"right",
      render: (_, record) => (
        <Dropdown menu={{ items: menuItems(record) }} trigger={["click"]}>
          <Tooltip title="More actions">
            <MoreOutlined style={{ fontSize: "18px", cursor: "pointer" }} />
          </Tooltip>
        </Dropdown>
      ),
    },
  ];
  

  const fetchData = async (pageIndex, pageSize, searchQuery = "") => {
    setLoading(true);
    const requestBody = {
      pageIndex: pageIndex - 1,
      pageSize,
      searchString: searchQuery,
    };
    const response = await postApi(GET_ALL_CAMPAIGNS, requestBody);
    const { statusCode, error, data } = response;
    console.log(response);
    if (statusCode === 200) {
      const transformedData = data.map((campaign) => ({
        ...campaign,
        customerName: campaign.assignedUsers[0]?.userName || "",
        projectNo: campaign.assignedUsers[0]?.projectNo || "",
        assignedDate: campaign.assignedUsers[0]?.assignedDate || "",
      }));
      setData(transformedData);
      setTotalCount(response.totalCount);
      setLoading(false);
    } else {
      ToastHelper.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(pageIndex, pageSize);
  }, [pageIndex, pageSize]);

  const getPageSizeOptions = () => {
    if (totalCount <= 50) {
      return ["10", "20", "50"];
    } else {
      return ["10", "20", "50", "100"];
    }
  };

  return (
    <div className="campaigntable">
      <div className="campaigntable_header">
        <div className="campaigntable_header_left">
          <h2>Campaign Management</h2>
        </div>
        <div className="campaigntable_header_right">
          <input
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={handleSearch}
          />
        </div>
      </div>

      {loading ? (
        <Flex align="center" justify="center" gap="middle">
          <Spin />
        </Flex>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <Table
          columns={columns}
          dataSource={data}
          pagination={{
            current: pageIndex,
            pageSize,
            total: totalCount,
            showSizeChanger: true,
            pageSizeOptions: getPageSizeOptions(),
            onChange: (page, size) => {
              setPageIndex(page);
              setPageSize(size);
            },
          }}
          scroll={{
            x: 1500,
            y: tableHeight || 300,
          }}
          rowKey="campaignID"
        />
      )}
    </div>
  );
};

export default CampaignTable;
