import React, { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import "./CustomerTable.scss";
import { Table, Tooltip, Dropdown, Spin, Checkbox, Flex, Card } from "antd";
import { AntDesignOutlined, MoreOutlined } from "@ant-design/icons";
import CustomButton from "../../../Components/CustomButton/CustomButton";
import { getApi, postApi } from "../../../Utils/apiService";
import {
  ADD_CAMPAIGNS_FOR_USER,
  GET_ACTIVE_USERS,
  GET_ASSIGNED_CAMPAIGNS,
  GET_UNASSIGNED_CAMPAIGNS,
  INACTIVE_USER,
  REMOVE_CAMPAIGNS_FOR_USER,
} from "../../../Utils/apiPath";
import dayjs from "dayjs";
import CustomModal from "../../../Components/CustomModal/CustomModal";
import useTableHeight from "../../../Services/TableHeight";
import ToastHelper from "../../../Services/ToastHelper";

const CustomerTable = ({ onAddClick, onEditClick }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [campaignData, setCampaignData] = useState([]);
  const [selectedCampaignIds, setSelectedCampaignIds] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [assignedCampaigns, setAssignedCampaigns] = useState([]);
  const [isAssignMode, setIsAssignMode] = useState(true);
  const [mergedCampaignData, setMergedCampaignData] = useState([]);
  const tableHeight = useTableHeight();

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedCustomerId(null);
  };

  const debouncedSearch = useCallback(
    debounce((query) => {
      fetchData(pageIndex, pageSize, query);
    }, 500),
    []
  );

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    debouncedSearch(e.target.value);
  };

  const handleSecondaryAction = () => {
    handleCloseModal();
    setSelectedCampaignIds(null);
    setSelectedCustomerId(null);
    setCampaignData([]);
  };

  const handleAssignClick = async (id) => {
    setSelectedCustomerId(id);
    setIsModalOpen(true);
    setIsAssignMode(true);

    try {
      const { statusCode, data, error } = await postApi(
        GET_UNASSIGNED_CAMPAIGNS
      );
      if (statusCode === 200 && data) {
        setCampaignData(data);
      } else {
        setCampaignData([]);
        ToastHelper.error(error);
      }
    } catch (error) {
      console.error("Failed to fetch campaign data", error);
      setCampaignData([]);
    }
  };

  const handleUnassignClick = async (id) => {
    setSelectedCustomerId(id);
    setIsModalOpen(true);
    setIsAssignMode(false);

    try {
      const { statusCode, data, message, error } = await getApi(
        GET_ASSIGNED_CAMPAIGNS,
        { params: { userId: id } }
      );
      if (statusCode === 200 && data) {
        setAssignedCampaigns(data);
      } else {
        setAssignedCampaigns([]);
        ToastHelper.error(error);
      }
    } catch (error) {
      console.error("Failed to fetch campaign data", error);
      setAssignedCampaigns([]);
    }
  };

  const handleInActiveUser = async (id, currentIsActiveStatus) => {
    setSelectedCustomerId(id);
    const payload = {
      isactive: !currentIsActiveStatus,
      userId: id,
    };
    const { statusCode, message, error } = await postApi(
      INACTIVE_USER,
      payload
    );
    if (statusCode === 200) {
      ToastHelper.success(message);
      fetchData(pageIndex, pageSize);
    } else {
      ToastHelper.error(error);
    }
  };

  useEffect(() => {
    if (isAssignMode) {
      setMergedCampaignData(campaignData);
    } else {
      setMergedCampaignData(assignedCampaigns);
    }
  }, [campaignData, assignedCampaigns, isAssignMode]);

  const menuItems = (id, record) => [
    {
      key: "edit",
      label: <a onClick={() => onEditClick(record)}>Edit</a>,
    },
    {
      key: "assign",
      label: <a onClick={() => handleAssignClick(id)}>Assign</a>,
    },
    {
      key: "unassign",
      label: <a onClick={() => handleUnassignClick(id)}>Unassign</a>,
    },
    {
      key: "inactive",
      label: <a onClick={() => handleInActiveUser(id)}>Delete</a>,
    },
  ];

  const columns = [
    {
      title: "SL",
      dataIndex: "r",
      key: "serialNo",
      width: 3,
      render: (_, __, index) => (pageIndex - 1) * pageSize + index + 1,
    },
    {
      title: "Project No",
      dataIndex: "projectId",
      key: "projectnumber",
      width: 6,
    },
    {
      title: "Company Name",
      dataIndex: "projectName",
      key: "companyname",
      width: 10,
    },
    {
      title: "Customer Name",
      dataIndex: "name",
      key: "customerName",
      width: 15,
    },
    {
      title: "Phone",
      dataIndex: "phoneNumber",
      key: "phone",
      width: 10,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 20,
    },
    {
      title: "Status(Active/Inactive)",
      dataIndex: "isActive",
      key: "status",
      width: 10,
      render: (isActive) => (isActive ? "Active" : "Inactive"),
    },
    {
      title: "Account Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      width: 10,
      render: (text) => dayjs(text).format("DD-MM-YYYY"),
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 4 ,
      render: (_, record) => (
        <Dropdown
          menu={{ items: menuItems(record?.id, record) }}
          trigger={["click"]}
        >
          <Tooltip title="More actions">
            <MoreOutlined style={{ fontSize: "18px", cursor: "pointer" }} />
          </Tooltip>
        </Dropdown>
      ),
    },
  ];

  const fetchData = async (pageIndex, pageSize, searchQuery = "") => {
    setLoading(true);
    try {
      const requestBody = {
        pageIndex: pageIndex - 1,
        pageSize,
        searchString: searchQuery,
      };

      const response = await postApi(GET_ACTIVE_USERS, requestBody);

      if (response && response.data) {
        setData(response?.data);
        setTotalCount(response?.totalCount);
      }
    } catch (err) {
      setError("Failed to fetch customer data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(pageIndex, pageSize);
  }, [pageIndex, pageSize]);

  const getPageSizeOptions = () => {
    if (totalCount <= 50) {
      return ["10", "20", "50"];
    } else {
      return ["10", "20", "50", "100"];
    }
  };

  const handleCheckboxChange = (id) => {
    setSelectedCampaignIds((prevIds) =>
      prevIds?.includes(id)
        ? prevIds?.filter((id) => id !== id)
        : [...prevIds, id]
    );
  };

  const handleConfirm = async () => {
    const api = isAssignMode
      ? ADD_CAMPAIGNS_FOR_USER
      : REMOVE_CAMPAIGNS_FOR_USER;
    const payload = {
      userId: selectedCustomerId,
      campaignIds: selectedCampaignIds,
    };

    const { statusCode } = await postApi(api, payload);
    if (statusCode === 200) {
      handleCloseModal();
    }
  };

  const columns2 = [
    {
      title: "Campaign Name",
      dataIndex: "campaignName",
      key: "campaignName",
    },
    {
      title: isAssignMode ? "Add" : "Remove",
      key: "action",
      render: (_, record) => (
        <Checkbox
          checked={selectedCampaignIds.includes(record.id)}
          onChange={() => handleCheckboxChange(record.id)}
        />
      ),
    },
  ];

  return (
    <div className="customertable">
      <div className="customertable_header">
        <div className="customertable_header_left">
          <h2>Customer Management</h2>
        </div>
        <div className="customertable_header_right">
          <input
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={handleSearch}
          />
          <CustomButton
            variant="primary"
            size="medium"
            onClick={onAddClick}
            icon={<AntDesignOutlined />}
          >
            Add
          </CustomButton>
        </div>
      </div>
      <CustomModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title={isAssignMode ? "Assign Campaigns" : "Unassign Campaigns"}
        showPrimaryButton={true}
        showSecondaryButton={true}
        onPrimaryClick={handleConfirm}
        onSecondaryClick={handleSecondaryAction}
        primaryLabel={isAssignMode ? "Add" : "Remove"}
        secondaryLabel="Cancel"
      >
        <div className="table-container">
          <Table
            columns={columns2}
            dataSource={mergedCampaignData}
            rowKey="campaignID"
            pagination={false}
            scroll={{ y: 270 }}
            sticky
          />
        </div>
      </CustomModal>

      {loading ? (
        <Flex align="center" justify="center" gap="middle">
          <Spin />
        </Flex>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <Table
          columns={columns}
          dataSource={data}
          pagination={{
            current: pageIndex,
            pageSize,
            total: totalCount,
            showSizeChanger: true,
            pageSizeOptions: getPageSizeOptions(),
            onChange: (page, size) => {
              setPageIndex(page);
              setPageSize(size);
            },
          }}
          scroll={{
            x: 1500,
            y: tableHeight,
          }}
          rowKey="id"
        />
      )}
    </div>

  );
};

export default CustomerTable;
